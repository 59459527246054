import React from "react"
import moment from "moment"
import Headline from "../ContentTypes/Headline"
import Text from "../ContentTypes/Text"

import SbEditable from "storyblok-react"

import "./styles.scss"
import "moment/min/locales"

import { withTranslation } from "react-i18next"

const News = ({ blok, title, date, language, slug, t }) => {

  moment.locale(language)
  
  return (
      <div className="container news-article">
          <SbEditable content={blok}>
            <article className="news-body">
              <Headline blok={{ level: "h2", "text": title }} />
              <div className="meta-information">{ moment(blok.date).format('DD.MM.YYYY') } | { blok.source }</div>
              <Text blok={{ text: blok.text }} />
              {typeof blok.link !== "undefined" && blok.link !== null &&
              <div className="button-wrapper">
                <a className="button" href={blok.link.url} target="_blank" rel="norefferer">Orginal-Artikel lesen</a>
              </div>
              }
            </article>
          </SbEditable>
      </div>
  )
}

export default withTranslation()(News)