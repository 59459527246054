import React from "react"
import moment from "moment"

import News from '../components/News/index'
import Layout from "../components/Layout/index"
import StoryblokService from '../utils/storyblok-service'
import LocalesService from '../utils/locales-service'
import ImagesService from '../utils/images-service'
import i18n from "../i18n/i18n"

import SEO from "../components/SEO/index"

class BlogPost extends React.Component {

  constructor(props) {
    super()
    this.props = props

    this.state = {
      story: {
        content: this.props.pageContext.story ? JSON.parse(this.props.pageContext.story.content) : {},
        language: this.props.pageContext.language,
        slug: this.props.pageContext.slug
      },
      headline: this.props.pageContext.headline
    }
    i18n.changeLanguage(this.props.pageContext.language)
  }

  async getInitialStory() {
    StoryblokService.setQuery(this.props.location.search)
    let { data: { story } } = await StoryblokService.get(`cdn/stories/${this.props.pageContext.story.full_slug}`, { resolve_relations: 'blogpost.author,blogpost.relatedPosts' })
    return story
  }

  async componentDidMount() {
    let story = await this.getInitialStory()

    let locales = LocalesService(story.alternates)
    this.setState({ locales })

    if(story.content) this.setState({ story })
    if(story.name) this.setState({ headline: story.name })
    setTimeout(() => StoryblokService.initEditor(this), 200)
  }

  render() {
    return (
       <Layout location={this.props.location}>
         <SEO 
          title={this.state.headline || ''}
          lang={this.props.pageContext.language}
          prefix="og: https://ogp.me/ns# article: https://ogp.me/ns/article# fb: https://ogp.me/ns/fb#"
          description={this.state.story.content.teaser}
          meta={[
          {
            property: 'og:locale',
            content: this.props.pageContext.language
          }, {
            property: 'og:type',
            content: 'article'
          }, {
            property: 'og:title',
            content: this.state.headline
          }, {
            property: 'og:description',
            content: this.state.story.content.teaser
          }, {
            property: 'og:locale',
            content: this.props.pageContext.language
          }, {
            property: 'og:site_name',
            content: 'conduco labs'
          }, {
            property: 'og:image',
            content: ImagesService(this.state.story.content.image.filename, true, { path: '1920x1080/smart'})
          }, {
            property: 'og:image:width',
            content: '1920'
          }, {
            property: 'og:image:height',
            content: '1080'
          }, {
            property: 'og:image:alt',
            content: this.state.story.content.image.alt
          }, {
            property: 'article:published_time',
            content: moment(this.state.story.first_published_at).toISOString()
          }, {
            property: 'article:modified_time',
            content: moment(this.state.story.published_at).toISOString()
          }, {
            property: 'og:updated_time',
            content: moment(this.state.story.published_at).toISOString()
          }, {
            property: 'og:url',
            content: `https://condu.co/${ this.props.pageContext.slug }`
          }, {
            property: 'fb:app_id',
            content: '815996722311997'
          }, {
            property: 'article:publisher',
            content: 'https://www.facebook.com/conducolabs'
          }, {
            property: 'twitter:title',
            content: this.state.headline
          }, {
            property: 'twitter:description',
            content: this.state.story.content.teaser
          }]}
          locales={this.state.locales}
          />
         <News blok={this.state.story.content} title={this.state.headline} date={this.state.story.first_published_at} language={this.props.pageContext.language} slug={this.props.pageContext.slug} />
      </Layout>
    )
  }
}

export default BlogPost